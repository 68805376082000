<template>
    <div id="dashboard">
        <DashboardHeader />

        <div class="d-flex flex-column px-2">
            <div class="d-flex flex-column flex-md-row w-100 mb-2 mb-md-5">
                <ImageTile text="Feneroo proti růstu cen!" image="img-1" :overlay="false" class="mr-0 mr-md-2 mb-2 mb-md-0" />
                <SimpleGraphTile id="gas-price-chart" graphTitle="Cena plynu" yAxisTitle="Cena plynu (Kč/m<sup>3</sup>)" :graphData="gasPriceData" class="overview-chart col-12 col-md-4 col-lg-3 mr-0 mr-md-2 mb-2 mb-md-0" />
                <SimpleGraphTile id="gas-saved-chart" graphTitle="Úspory plynu po zavedení Feneroo" yAxisTitle="Objem uspořeného plynu (m<sup>3</sup>)" :graphData="gasSavedData" class="overview-chart col" />
            </div>

            <div class="connector left"></div>

            <div class="d-flex flex-column flex-md-row w-100 mb-2 mb-md-5">
                <div class="border bg-white rounded shadow-sm col-12 col-md-4 col-lg-3 order-2 order-md-0 p-4 mr-0 mr-md-2 d-flex flex-column">
                    <img :src="require('@/assets/images/energy-graphic.png')" class="img-fluid my-auto" />
                </div>
                <StructureGraphTile id="gas-saving-structure-chart" graphTitle="Struktura úspor dle analýzy Feneroo" yAxisTitle="Relativní úspora plynu (%)" :graphData="gasSavingStructureData" class="overview-chart col order-1 mr-0 mr-md-2 mb-2 mb-md-0" />
                <ImageTile text="Víme, kde šetříme..." image="img-2" :topright="true" class="order-0 order-md-2 mb-2 mb-md-0" />
            </div>

            <div class="connector right"></div>

            <div class="d-flex flex-column flex-md-row w-100 mb-2 mb-md-5">
                <ImageTile text="Víme, co očekávat..." image="img-3" class="mr-0 mr-md-2 mb-2 mb-md-0" />
                <ConsumptionGraphTile id="gas-consumption-chart" graphTitle="Spotřeba plynu v topných sezónách" yAxisTitle="Objem plynu (m<sup>3</sup>)" yAxis2Title="Teplota (°C)" :graphData="gasConsumptionData" class="overview-chart col" />
            </div>

            <div class="connector left"></div>

            <div class="d-flex flex-column flex-md-row w-100 mb-2">
                <SimpleGraphTile id="money-saved-chart" graphTitle="Úspory na ceně plynu" yAxisTitle="Cena plynu (Kč)" :graphData="moneySavedData" class="overview-chart col order-1 order-md-0 mr-0 mr-md-2 mb-2 mb-md-0" />
                <ImageTile text="Víme, kolik nám Feneroo šetří!" image="img-4" :overlay="false" :topright="true" class="order-0 order-md-1 mb-2 mb-md-0" />
            </div>

            <ContactTile />
        </div>

        <DashboardFooter />
    </div>
</template>

<script>
import { eventBus } from '../store.js'

import DashboardHeader from './DashboardHeader.vue'
import DashboardFooter from './DashboardFooter.vue'
import ImageTile from './ImageTile.vue'
import SimpleGraphTile from './SimpleGraphTile.vue'
import StructureGraphTile from './StructureGraphTile.vue'
import ConsumptionGraphTile from './ConsumptionGraphTile.vue'
import ContactTile from './ContactTile.vue'

export default {
    name: 'DashboardOverview',
    components: {
        DashboardHeader,
        DashboardFooter,
        ImageTile,
        SimpleGraphTile,
        StructureGraphTile,
        ConsumptionGraphTile,
        ContactTile
    },

    props: {
        barwidth: {
            type: Number,
            default: 0.77
        }
    },

    data() {
        return {
            gasPriceData: [
                {
                    name: "cena plynu",

                    x: null,
                    y: null,

                    type: 'scatter',
                    mode: 'lines',

                    line: {
                        color: 'rgb(250, 110, 79)',
                        width: 4
                    }
                }
            ],

            gasSavedData: [
                {
                    name: "úspora plynu",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: null,
                        cmin: -1,
                        cmax: 1,
                        colorscale: [[0, 'rgba(250, 110, 79, 0.5)'], [1, 'rgb(161, 222, 144)']]
                    }
                }
            ],

            gasSavingStructureData: [
                {
                    name: "technické úspory",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: 'rgb(161, 222, 144)'
                    }
                },
                {
                    name: "organizační úspory",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: 'rgb(255, 243, 152)'
                    }
                },
                {
                    name: "teplotní úspory",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: 'rgba(98, 136, 200, 0.35)'
                    }
                },
                {
                    name: "celková relativní úspora",

                    x: null,
                    y: null,

                    type: 'scatter',
                    mode: 'markers',

                    marker: {
                        color: 'rgb(226, 154, 89)',
                        size: 12,
                        symbol: 'diamond-wide'
                    }
                },
                {
                    name: "trend úspor plynu",

                    x: null,
                    y: null,

                    type: 'scatter',
                    mode: 'lines',

                    line: {
                        color: 'rgba(99, 24, 141, 0.4)',
                        width: 4,
                        dash: 'dash'
                    }
                }
            ],

            gasConsumptionData: [
                {
                    name: "spotřeba plynu",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: 'rgb(252, 220, 180)',
                        line: {
                            width: 3,
                            color: 'rgb(226, 154, 89)'
                        }
                    }
                },
                {
                    name: "spotřeba plynu (odhad)",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: 'rgba(252, 220, 180, 0.5)'
                    }
                },
                {
                    name: "průměrná roční teplota",

                    x: null,
                    y: null,
                    yaxis: 'y2',

                    type: 'scatter',
                    mode: 'lines+markers',

                    line: {
                        color: 'rgb(98, 136, 200)',
                        width: 1
                    },

                    marker: {
                        color: 'rgb(98, 136, 200)',
                        size: 5
                    }
                },
                {
                    name: "letošní teplota (odhad)",

                    x: null,
                    y: null,
                    yaxis: 'y2',

                    type: 'scatter',
                    mode: 'markers',

                    marker: {
                        color: 'rgb(98, 136, 200)',
                        size: 12,
                        symbol: 'diamond-wide'
                    }
                },
                {
                    name: "trend spotřeby plynu",

                    x: null,
                    y: null,

                    type: 'scatter',
                    mode: 'lines',

                    line: {
                        color: 'rgba(99, 24, 141, 0.4)',
                        width: 4,
                        dash: 'dash'
                    }
                }
            ],

            moneySavedData: [
                {
                    name: "úspory na ceně plynu",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: null,
                        cmin: -1,
                        cmax: 1,
                        colorscale: [[0, 'rgba(250, 110, 79, 0.5)'], [1, 'rgb(161, 222, 144)']]
                    },

                    text: null,
                    textposition: 'auto',
                    textfont: {
                        family: 'Mukta',
                        size: 16
                    }
                },
                {
                    name: "letošní úspora (odhad)",

                    x: null,
                    y: null,

                    type: 'bar',
                    width: 1000 * 3600 * 24 * 365 * this.barwidth,

                    marker: {
                        color: 'rgba(161, 222, 144, 0.4)'
                    },

                    text: null,
                    textposition: 'auto',
                    textfont: {
                        family: 'Mukta',
                        size: 16
                    }
                },
            ]
        }
    },

    created: function() {
        eventBus.$on('onSeasonOverviewRetrieved', (data) => {
            // console.log(data)

            // Gas price chart
            if(data.gasprice) {
                var x = []
                var yPrice = []

                for(var i = 0; i < data.gasprice.length; i++) {
                    x.push(data.gasprice[i].datetime)
                    yPrice.push(data.gasprice[i].price)
                }

                this.gasPriceData[0].x = x
                this.gasPriceData[0].y = yPrice
            }

            if(data.overview) {
                var s = null
                var seasons = []
                var seasonEst = []

                var yGasSaved = []
                var yGasSavedColorSpace = []
                var yGasSavingTech = []
                var yGasSavingOrg = []
                var yGasSavingTemp = []
                var yGasSavingTot = []
                var yTndGasSave = []

                var yGasConsumed = []
                var yGasConsumedEst = []
                var yTemp = []
                var yTempEst = []
                var yTndGasUse = []

                var yMoneySaved = []
                var yMoneySavedEst = []
                var yMoneySavedColorSpace = []

                // Get current month - are we in season or out?
                let m = new Date().getMonth() + 1

                for(var j = 0; j < data.overview.length; j++) {
                    s = new Date(data.overview[j].season, 0, 1)
                    seasons.push(s.toISOString())

                    // Gas saved chart
                    yGasSaved.push(data.overview[j].gassave)
                    yGasSavedColorSpace.push((data.overview[j].gassave >= 0) ? 1 : -1)

                    // Savings structure
                    yGasSavingTech.push(data.overview[j].relgassave_tech)
                    yGasSavingOrg.push(data.overview[j].relgassave_org)
                    yGasSavingTemp.push(data.overview[j].relgassave_temp)
                    yGasSavingTot.push(data.overview[j].relgassave_tot)
                    yTndGasSave.push(data.overview[j].tnd_relsave)

                    // Consmuption
                    yGasConsumed.push(data.overview[j].gasvol)
                    yTemp.push(data.overview[j].temp)
                    yTndGasUse.push(data.overview[j].tnd_gasvol)

                    // Current estimates
                    if(j == data.overview.length - 1) {
                        seasonEst.push(s.toISOString())

                        yGasConsumedEst.push(data.overview[j].gasvol_est - data.overview[j].gasvol) // TODO minus or not
                        yTempEst.push(data.overview[j].temp_est)

                        if(m > 5 && m < 9) {
                            yMoneySavedEst.push(data.overview[j].costsave)
                        } else {
                            yMoneySavedEst.push(Math.max(0, data.overview[j].costsave_est - data.overview[j].costsave))
                        }
                    }

                    if(j == data.overview.length - 1 && m > 5 && m < 9) {
                        // Out of season the numbers are off .. thus we need to use the current money saved as the estimate and
                        // the current money saved is set to 0 for visualisation purposes
                        yMoneySaved.push(0)
                    } else {
                        // Money saved .. usual case
                        yMoneySaved.push(data.overview[j].costsave)
                    }

                    yMoneySavedColorSpace.push((data.overview[j].costsave >= 0) ? 1 : -1)
                }

                this.gasSavedData[0].x = seasons

                this.gasSavingStructureData[0].x = seasons
                this.gasSavingStructureData[1].x = seasons
                this.gasSavingStructureData[2].x = seasons
                this.gasSavingStructureData[3].x = seasons
                this.gasSavingStructureData[4].x = seasons

                this.gasConsumptionData[0].x = seasons
                this.gasConsumptionData[1].x = seasonEst
                this.gasConsumptionData[2].x = seasons
                this.gasConsumptionData[3].x = seasonEst
                this.gasConsumptionData[4].x = seasons

                this.moneySavedData[0].x = seasons
                this.moneySavedData[1].x = seasonEst

                this.gasSavedData[0].y = yGasSaved
                this.gasSavedData[0].marker.color = yGasSavedColorSpace

                this.gasSavingStructureData[0].y = yGasSavingTech
                this.gasSavingStructureData[1].y = yGasSavingOrg
                this.gasSavingStructureData[2].y = yGasSavingTemp
                this.gasSavingStructureData[3].y = yGasSavingTot
                this.gasSavingStructureData[4].y = yTndGasSave

                this.gasConsumptionData[0].y = yGasConsumed
                this.gasConsumptionData[1].y = yGasConsumedEst
                this.gasConsumptionData[2].y = yTemp
                this.gasConsumptionData[3].y = yTempEst
                this.gasConsumptionData[4].y = yTndGasUse

                this.moneySavedData[0].y = yMoneySaved
                this.moneySavedData[0].marker.color = yMoneySavedColorSpace
                this.moneySavedData[1].y = yMoneySavedEst

                this.moneySavedData[0].text = yMoneySaved.map(function(i, idx) {
                    if (idx == yMoneySaved.length - 1) {
                        if (m <= 5 || m >= 9) {
                            return '~ ' + Math.floor(i).toString() + ' Kč'
                        } else {
                            return null
                        }
                    } // TODO show .. but how to force outside??
                    return Math.floor(i).toString() + ' Kč'
                })
                this.moneySavedData[1].text = yMoneySavedEst.map(function(i) {
                    // TODO show not estimate relative to current saving but actually current+estimate value
                    if (m > 5 && m < 9) {
                        return '~ ' + Math.floor(i).toString() + ' Kč'
                    } else {
                        return null
                    }
                })
            }
        })

        this.getSeasonOverview()
    }
}
</script>
