import Vue from 'vue'
import App from './App.vue'

import DashboardOverview from './components/DashboardOverview.vue'

import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { store, variables } from './store.js'

// Mock for deployment in development (for debugging and design purposes):
//import { getSeasons, getTotals, getGasUse, getGasSave, getDataForInterval, getSeasonOverview } from './mixins/mock-db-access.js'
// Real for live deployment:
import { getSeasons, getTotals, getGasUse, getGasSave, getDataForInterval, getSeasonOverview } from './mixins/restful-db-access.js'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Vue Router setup
const routes = [
    { path: '/', component: DashboardOverview },
    { path: '/seasons', component: () => import('./components/DashboardSeasons.vue')  },
    { path: '/scenarios', component: () => import('./components/DashboardScenarios.vue') },
    { path: '/about', component: () => import('./components/DashboardAbout.vue') }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

// Global variables like version, name etc; and method mixins for data retrieval
Vue.mixin({
    data: function() {
        return variables
    },
    methods: {
        // Methods for data retrieval
        getSeasons,
        getTotals,
        getGasUse,
        getGasSave,
        getDataForInterval,
        getSeasonOverview
    }
})

new Vue({
    router,
    store,
    render: f => f(App),
}).$mount('#app')
