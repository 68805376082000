import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state = {
    refyear: null,
    selyear: null,

    refinterval: null,
    studyinterval: null
}

const mutations = {
    setRefYear(state, year) {
        state.refyear = year
    },

    setSelYear(state, year) {
        state.selyear = year
    },

    setRefInterval(state, data) {
        let ref_from = data[0]
        let ref_to = data[1]

        if(!ref_from || !ref_to) {
            return false
        }
        
        state.refinterval = String(ref_from.getFullYear()) +
                            String(ref_from.getMonth() + 1).padStart(2, '0') +
                            String(ref_from.getDate()).padStart(2, '0') + '-' +
                            String(ref_to.getFullYear()) +
                            String(ref_to.getMonth() + 1).padStart(2, '0') +
                            String(ref_to.getDate()).padStart(2, '0')
    },

    setStudyInterval(state, data) {
        let study_from = data[0]
        let study_to = data[1]

        if(!study_from || !study_to) {
            return false
        }

        state.studyinterval = String(study_from.getFullYear()) +
                            String(study_from.getMonth() + 1).padStart(2, '0') +
                            String(study_from.getDate()).padStart(2, '0') + '-' +
                            String(study_to.getFullYear()) +
                            String(study_to.getMonth() + 1).padStart(2, '0') +
                            String(study_to.getDate()).padStart(2, '0')
    }
}

export const store = new Vuex.Store(
    {
        state: state,
        mutations: mutations
    }
)

/* === EVENT BUS === */

export const eventBus = new Vue();

/* === VARIABLES === */

export const variables = {
    appName: 'Feneroo',
    appVersion: 'v2.5',
    appRelease: '12 Sep 2022',
    appCopyrightRange: '2021 – 2024',

    dynamicDataUpdateInterval: 5 * 60 * 1000 // every 5 minutes (in milliseconds)
}