<template>
    <div class="contact-tile border rounded shadow-sm text-center p-4">
        <p class="font-weight-bold"><a href="https://fesordata.cz/" target="_blank"><b-icon icon="box-arrow-in-up-right" class="mr-4" />www.fesordata.cz</a></p>
        <p class="font-weight-light font-italic"><a href="mailto:info@fesordata.cz"><b-icon icon="inbox" class="mr-4" />info@fesordata.cz</a></p>
        <p class="mb-0"><b-icon icon="telephone-outbound" class="mr-4" />(+420) 776 517 152</p>
    </div>
</template>

<script>
export default {
    name: 'ContactTile'
}
</script>
