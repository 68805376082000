<template>
    <div id="app-footer" class="d-flex justify-content-between align-items-center m-2 px-2 py-2 border bg-white rounded shadow-sm">
        <span class="flex-filler"></span>
        <span id="app-copyright">Copyright &copy; {{appCopyrightRange}} <a href="https://fesordata.cz/" target="_blank">FesorData s.r.o.</a></span>
        <svg
        width="31"
        height="31"
        viewBox="0 0 39.687499 39.687499">
        <g
            transform="translate(-46.333452,-62.964409)">
            <title>FesorData s.r.o.</title>
            <g
            transform="matrix(0.15006712,0,0,0.15006712,44.907814,61.538771)">
            <path
                d="m 62.082,9.5 h 159.301 c 29.129,0 52.582,23.453 52.582,52.582 v 159.301 c 0,29.129 -23.453,52.582 -52.582,52.582 H 62.082 C 32.953,273.965 9.5,250.512 9.5,221.383 V 62.082 C 9.5,32.953 32.953,9.5 62.082,9.5 Z"
                style="fill:#00202d;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path22" />
            <path
                d="m 226.195,141.852 c 0,11.132 -2.043,21.117 -6.125,29.949 -4.082,8.777 -9.238,15.523 -15.468,20.23 -4.684,3.535 -9.821,5.996 -15.407,7.387 -5.586,1.395 -12.203,2.09 -19.851,2.09 h -33.84 V 81.957 h 34.805 c 7.824,0 14.57,0.832 20.242,2.488 5.672,1.61 10.441,3.91 14.308,6.907 6.618,5.031 11.817,11.828 15.598,20.394 3.824,8.508 5.738,18.547 5.738,30.106 z m -25.59,-0.239 c 0,-7.871 -1.16,-14.586 -3.48,-20.152 -2.277,-5.621 -5.91,-10.012 -10.895,-13.168 -2.535,-1.551 -5.132,-2.598 -7.796,-3.133 -2.621,-0.586 -6.598,-0.883 -11.926,-0.883 h -6.254 v 74.828 h 6.254 c 5.887,0 10.207,-0.32 12.957,-0.96 2.75,-0.7 5.433,-1.93 8.055,-3.696 4.511,-3.211 7.82,-7.492 9.925,-12.844 2.106,-5.406 3.16,-12.07 3.16,-19.992 z"
                style="fill:#32c310;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path24" />
            <path
                d="m 57.27,81.957 v 23.121 h 44.152 v 96.426 h 24.625 V 81.957 Z m 5.929,45.367 v 23.067 h 28.196 v -23.067 z"
                style="fill:#e9d8cc;fill-opacity:1;fill-rule:nonzero;stroke:none"
                id="path26" />
            </g>
        </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'DashboardFooter'
}
</script>