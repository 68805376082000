<template>
    <div class="d-flex flex-column text-center border bg-white rounded shadow-sm">
        <div class="w-100 pt-2 graph-card-header">
            <span class="h5">{{graphTitle}}</span>
        </div>
        
        <div class="mt-2 px-3">
            <Plotly class="plotly-container" :data="graphData" :layout="graphLayout"
                :staticPlot="true" :displaylogo="false"/>
        </div>
    </div>
</template>

<script>
import { Plotly } from 'vue-plotly'

export default {
    name: 'SimpleGraphTile',

    components: {
        Plotly
    },

    props: {
        graphTitle: {
            type: String
        },

        graphData: {
            type: Array,
            required: true
        },

        yAxisTitle: {
            type: String
        }
    },

    data() {
        return {
            graphLayout: {
                margin: {
                    l: 50,
                    r: 5,
                    t: 0,
                    b: 30
                },

                xaxis: {
                    showgrid: false
                },

                barmode: 'stack',

                yaxis: {
                    title: {
                        text: this.yAxisTitle,
                        font: {
                            family: 'Mukta'
                        }
                    },
                    automargin: true,

                    showgrid: false
                },

                showlegend: false
            }
        }
    }
}
</script>

<style>
.js-plotly-plot, .plot-container {
    max-height: 255px!important;
}
</style>