<template>
    <div class="d-inline-flex w-100">
        <b-button id="app-header-button" class="mr-0 ml-2 my-2" v-b-toggle.menu-drawer>
            <b-icon id="menu-icon" icon="three-dots" />
        </b-button>
        <div id="app-header" class="d-flex flex-column flex-lg-row align-items-center w-100 m-2 pr-2 pl-3 py-2 border bg-white rounded shadow-sm">
            <slot></slot>

            <span class="d-block flex-grow-1"></span>

            <div id="title-group" class="order-0 order-lg-1">
                <span class="mr-1">powered by</span>
                <span id="app-title" class="h3 align-bottom">{{appName}}</span>
                <span id="app-version" class="align-top" :title="'Released on ' + appRelease">{{appVersion}}</span>

                <a href="https://fesordata.cz/cs/produkty/" target="_blank"><img src="@/assets/feneroo_logo.png" height="31" class="align-middle ml-2" title="Feneroo" /></a>
            </div>
        </div>

        <b-sidebar id="menu-drawer" backdrop>
            <b-list-group flush>
                <b-list-group-item to="/"><span class="h5"><b-icon icon="clipboard-data" class="mr-3" />Přehled</span></b-list-group-item>
                <b-list-group-item to="/seasons"><span class="h5"><b-icon icon="calendar-month-fill" class="mr-3" />Sezóny</span></b-list-group-item>
                <b-list-group-item to="/scenarios"><span class="h5"><b-icon icon="journal-bookmark-fill" class="mr-3" />Scénáře</span></b-list-group-item>
                <!---<b-list-group-item to="/about"><span class="h5"><b-icon icon="info-square-fill" class="mr-3" />O aplikaci</span></b-list-group-item>-->
            </b-list-group>
        </b-sidebar>
    </div>
</template>

<script>
export default {
    name: 'DashboardHeader'
}
</script>
