import axios from 'axios'
import { eventBus } from '../store'

const RESTFUL_API_LINK = "/rest/v1/"

function fetchApiData(link) {
    // Couldn't get this one working so using axios and event bus. Not the best solution though, not very Vuey...
    // Would like to learn the proper way of doing this for the next project or for this one in later development.

    return null
}

export function getSeasons(format = 1) {
    axios.get(RESTFUL_API_LINK + "seasons/?format=" + format)
        .then(response => {
            eventBus.$emit('onSeasonsRetrieved', response.data)
        })
        .catch(error => console.log(error))
}

export function getGasUse(selyear, refyear) {
    axios.get(RESTFUL_API_LINK + "gas-use/?season=" + selyear + "&ref=" + refyear)
        .then(response => {
            eventBus.$emit('onGasUseRetrieved', response.data)
        })
        .catch(error => console.log(error))
}

export function getGasSave(selyear, refyear) {
    axios.get(RESTFUL_API_LINK + "gas-save/?season=" + selyear + "&ref=" + refyear)
        .then(response => {
            eventBus.$emit('onGasSaveRetrieved', response.data)
        })
        .catch(error => console.log(error))
}

export function getTotals(selyear, refyear) {
    axios.get(RESTFUL_API_LINK + "totals/?season=" + selyear + "&ref=" + refyear)
        .then(response => {
            eventBus.$emit('onTotalsRetrieved', response.data)
        })
        .catch(error => console.log(error))
}

export function getDataForInterval(selinterval, refinterval) {
    axios.get(RESTFUL_API_LINK + "interval-data/?study=" + selinterval + "&ref=" + refinterval)
        .then(response => {
            eventBus.$emit('onDataForIntervalRetrieved', response.data)
        })
        .catch(error => console.log(error))
}

export function getSeasonOverview() {
    axios.get(RESTFUL_API_LINK + "season-overview/")
        .then(response => {
            eventBus.$emit('onSeasonOverviewRetrieved', response.data)
        })
        .catch(error => console.log(error))
}
