<template>
  <div id="app">
    <!--<Dashboard />-->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',

  created: function() {
    // Set the global document title. Not sure if this is the correct way, but it sure works!
    document.title = this.appName + " " + this.appVersion;
  }
}
</script>

<style>
  /* Import the global stylesheet. 
    In the future possibly TODO: use CSS pre-processors,
    such as LESS for ease of use. Not sure how to do that as of now. */
  @import './assets/styles/global.css';
</style>
