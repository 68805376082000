<template>
    <div class="d-flex flex-column text-center border bg-white rounded shadow-sm">
        <div class="w-100 pt-2 graph-card-header">
            <span class="h5">{{graphTitle}}</span>
        </div>
        
        <div class="mt-2 px-3">
            <Plotly class="plotly-container" :data="graphData" :layout="graphLayout"
                :staticPlot="true" :displaylogo="false"/>
        </div>
    </div>
</template>

<script>
import { Plotly } from 'vue-plotly'

export default {
    name: 'ConsumptionGraphTile',

    components: {
        Plotly
    },

    props: {
        graphTitle: {
            type: String
        },

        graphData: {
            type: Array,
            required: true
        },

        yAxisTitle: {
            type: String
        },

        yAxis2Title: {
            type: String
        }
    },

    data() {
        return {
            graphLayout: {
                margin: {
                    l: 45,
                    r: 5,
                    t: 0,
                    b: 20
                },

                xaxis: {
                    showgrid: false
                },

                barmode: 'stack',

                yaxis: {
                    title: {
                        text: this.yAxisTitle,
                        font: {
                            family: 'Mukta'
                        }
                    },
                    automargin: true,

                    showgrid: false
                },

                yaxis2: {
                    title: {
                        text: this.yAxis2Title,
                        font: {
                            family: 'Mukta'
                        }
                    },
                    automargin: true,

                    overlaying: 'y',
                    side: 'right',

                    showgrid: false,
                    zeroline: false
                },

                showlegend: true,
                legend: {
                    x: 0.5,
                    y: -0.1,
                    xanchor: 'center',
                    orientation: 'h',

                    itemclick: false,
                    itemdoubleclick: false,

                    font: {
                        family: 'Mukta'
                    }
                }
            }
        }
    }
}
</script>

<style>
#gas-consumption-chart .js-plotly-plot, #gas-consumption-chart .plot-container {
    max-height: 255px!important;
}
</style>