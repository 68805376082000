<template>
    <div class="image-tile rounded shadow-sm col-12 col-md-4 col-lg-3 col-xl-2 p-4">
        <!--:style="'background: url(' + require('@/assets/images/' + image + '.png') + ') no-repeat center;'"-->
        <div v-if="overlay" class="overlay"></div>

        <div v-if="image && !topright" class="icon">
            <img class="top" :src="require('@/assets/images/' + image + '.png')">
        </div>

        <div class="d-flex w-100 h-100" :class="{ 'align-items-end': !topright }">
            <span class="d-block w-100 image-tile-title" :class="{ 'text-right': topright }">{{text}}</span>
        </div>

        <div v-if="image && topright" class="icon">
            <img class="bottom" :src="require('@/assets/images/' + image + '.png')">
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageTile',
    props: {
        text: {
            type: String,
            required: true
        },

        image: {
            type: String,
            default: null
        },

        topright: {
            type: Boolean,
            default: false
        },

        overlay: {
            type: Boolean,
            default: true
        }
    }
}
</script>
